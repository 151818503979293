<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-tabs
      v-model="tab"
      color="purple"
    >
      <v-tab
        key="menunggu"
        class="text-capitalize"
      >
        Menunggu
      </v-tab>

      <v-tab
        key="semua"
        class="text-capitalize"
      >
        Semua
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <!-- TAB MENUNGGU -->
      <v-tab-item key="menunggu">
        <div style="padding: 12px">
          <v-data-table
            :headers="headers"
            :items="penarikanMenunggu"
            :items-per-page="25"
            class="elevation-0"
            mobile-breakpoint="100"
            hide-default-footer
          >
            <template v-slot:top>
              <v-row class="mb-6">
                <v-col
                  cols="12"
                  sm="4"
                  style="
                    display: flex;
                  "
                >
                  <v-text-field
                    v-model="search"
                    class="mt-3"
                    append-icon="mdi-magnify"
                    label="Cari"
                    color="purple"
                    outlined
                    dense
                    single-line
                    hide-details
                    clearable
                    @keyup.enter.native="filterData"
                    @click:append="filterData"
                    @click:clear="filterClear"
                  />
                </v-col>

                <v-spacer />

                <v-col
                  cols="12"
                  sm="6"
                  style="
                    justify-content: space-between;
                    display: flex;
                  "
                >
                  <div
                    class="d-flex ml-4"
                    style="flex-direction: column"
                  >
                    <span
                      class="font-common"
                      style="color: #7A1878"
                    >
                      Dana Xendit
                    </span>
                    <span
                      class="font-common-bold"
                      style="
                        font-size: 24px;
                        color: #7A1878;
                      "
                    >
                      {{ 'Rp. ' + xenditBalance | dotThousandSeparator }}
                    </span>
                  </div>

                  <div
                    class="d-flex mr-4"
                    style="flex-direction: column"
                  >
                    <span
                      class="font-common"
                    >
                      Total Dana Penarikan
                    </span>
                    <span
                      class="font-common-bold"
                      style="
                        font-size: 24px;
                      "
                    >
                      {{ 'Rp. ' + recap.jumlah_pending | dotThousandSeparator }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </template>

            <template v-slot:item.nominal="{ item }">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span>
                  Rp
                </span>

                <span>
                  {{ item.nominal | dotThousandSeparator }}
                </span>
              </div>
            </template>

            <template v-slot:item.actions="{ item }">
              <div
                class="action-container"
                style="
            width: 36px;
            height: 29px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #F2F2F2;
            box-sizing: border-box;
            border-radius: 4px;
            cursor: pointer;
          "
                @click="toDetail(item)"
              >
                <v-icon
                  class="action-icon"
                  style="font-size: 16px"
                >
                  fas fa-eye
                </v-icon>
              </div>
            </template>

            <template v-slot:footer>
              <div class="text-center my-5 py-3">
                <v-pagination
                  v-model="pageMenunggu"
                  :length="pageCountMenunggu"
                  color="purple"
                  @input="onChangePage"
                />
                <!-- <span style="font-size: 12px; font-style: italic;">
                  Page {{ page }} of {{ pageCount || 1 }}
                </span> -->
                <span style="font-size: 12px; font-style: italic;">
                  {{ itemCountMenunggu + ' dari ' + itemTotalMenunggu + ' ditampilkan' }}
                </span>
              </div>
              <!-- <v-row class="mt-n16">
                <v-col class="text-right pr-8">
                  <span style="font-size: 12px; font-style: italic;">
                    {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
                  </span>
                </v-col>
              </v-row> -->
            </template>
          </v-data-table>
        </div>
      </v-tab-item>

      <!-- TAB SEMUA -->
      <v-tab-item key="semua">
        <div style="padding: 12px">
          <v-data-table
            :headers="headers"
            :items="penarikan"
            :items-per-page="25"
            class="elevation-0"
            mobile-breakpoint="100"
            hide-default-footer
          >
            <template v-slot:top>
              <v-row class="ml-0 mb-6">
                <v-col
                  cols="12"
                  sm="4"
                  style="
                    display: flex;
                  "
                >
                  <v-text-field
                    v-model="search"
                    class="mt-3"
                    append-icon="mdi-magnify"
                    label="Cari"
                    color="purple"
                    outlined
                    dense
                    single-line
                    hide-details
                    clearable
                    @keyup.enter.native="filterData"
                    @click:append="filterData"
                    @click:clear="filterClear"
                  />
                </v-col>

                <v-spacer />

                <v-col
                  cols="12"
                  sm="6"
                  style="
                    justify-content: space-between;
                    display: flex;
                  "
                >
                  <div
                    class="d-flex ml-4"
                    style="flex-direction: column"
                  >
                    <span
                      class="font-common"
                      style="color: #7A1878"
                    >
                      Dana Xendit
                    </span>
                    <span
                      class="font-common-bold"
                      style="
                        font-size: 24px;
                        color: #7A1878;
                      "
                    >
                      {{ 'Rp. ' + xenditBalance | dotThousandSeparator }}
                    </span>
                  </div>

                  <div
                    class="d-flex mr-4"
                    style="flex-direction: column"
                  >
                    <span
                      class="font-common"
                    >
                      Total Dana Penarikan
                    </span>
                    <span
                      class="font-common-bold"
                      style="
                        font-size: 24px;
                      "
                    >
                      {{ 'Rp. ' + recap.jumlah_pending | dotThousandSeparator }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </template>

            <template v-slot:item.nominal="{ item }">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span>
                  Rp
                </span>

                <span>
                  {{ item.nominal | dotThousandSeparator }}
                </span>
              </div>
            </template>

            <template v-slot:item.actions="{ item }">
              <div
                class="action-container"
                style="
            width: 36px;
            height: 29px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #F2F2F2;
            box-sizing: border-box;
            border-radius: 4px;
            cursor: pointer;
          "
                @click="toDetail(item)"
              >
                <v-icon
                  class="action-icon"
                  style="font-size: 16px"
                >
                  fas fa-eye
                </v-icon>
              </div>
            </template>

            <template v-slot:item.status_string="{ item }">
              <v-chip :color="'#' + item.status_color">
                <span style="color: #FFFFFF">
                  {{ item.status_string }}
                </span>
              </v-chip>
            </template>

            <template v-slot:footer>
              <div class="text-center my-5 py-3">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  color="purple"
                  :total-visible="5"
                  @input="onChangePage"
                />
                <!-- <span style="font-size: 12px; font-style: italic;">
                  Page {{ page }} of {{ pageCount || 1 }}
                </span> -->
                <span style="font-size: 12px; font-style: italic;">
                  {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
                </span>
              </div>
              <!-- <v-row class="mt-n16">
                <v-col class="text-right pr-8">
                  <span style="font-size: 12px; font-style: italic;">
                    {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
                  </span>
                </v-col>
              </v-row> -->
            </template>
          </v-data-table>
        </div>
      </v-tab-item>

      <!-- DIALOG -->
      <v-dialog
        v-model="dialog"
        max-width="600"
      >
        <v-card class="px-4 pt-4 pb-10">
          <v-row>
            <v-col
              class="d-flex"
              style="justify-content: space-between"
            >
              <span class="font-common-black-bold">
                Verifikasi Penarikan
              </span>

              <span
                class="font-common"
                style="cursor: pointer"
                @click="dialog = false"
              >
                X
              </span>
            </v-col>
          </v-row>

          <template v-if="selectedItem !== null">
            <v-row>
              <v-col
                class="font-common"
                cols="3"
                sm="3"
              >
                Nama Merek
              </v-col>

              <v-col
                class="font-common-bold"
                cols="9"
                sm="9"
              >
                {{ selectedItem.user_company }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="font-common"
                cols="3"
                sm="3"
              >
                Nama Legal
              </v-col>

              <v-col
                class="font-common-bold"
                cols="9"
                sm="9"
              >
                {{ selectedItem.user_company_legal }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="font-common"
                cols="3"
                sm="3"
              >
                Nama Direktur
              </v-col>

              <v-col
                class="font-common-bold"
                cols="9"
                sm="9"
              >
                {{ selectedItem.user_name }}
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="font-common"
                cols="3"
                sm="3"
              >
                Bank Tujuan
              </v-col>

              <v-col
                class="font-common-bold"
                cols="9"
                sm="9"
              >
                {{ selectedItem.bank_name }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="font-common"
                cols="3"
                sm="3"
              >
                No. Rekening
              </v-col>

              <v-col
                class="font-common-bold"
                cols="9"
                sm="9"
              >
                {{ selectedItem.bank_acc_num }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="font-common"
                cols="3"
                sm="3"
              >
                Atas Nama
              </v-col>

              <v-col
                class="font-common-bold"
                cols="9"
                sm="9"
              >
                {{ selectedItem.bank_acc_name }}
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="font-common"
                cols="3"
                sm="3"
              >
                Nominal
              </v-col>

              <v-col
                class="font-common-bold"
                cols="9"
                sm="9"
              >
                {{ 'Rp. ' + selectedItem.nominal | dotThousandSeparator }}
              </v-col>
            </v-row>

            <!-- KONDISI HIDE / SHOW BAGIAN BAWAH -->
            <template v-if="selectedItem.show_approval_section">
              <v-row>
                <v-col>
                  <v-divider />
                </v-col>
              </v-row>

              <v-row v-if="withdrawalMod">
                <v-col
                  cols="3"
                  sm="3"
                  style="
                  display: flex;
                  flex-direction: column;
                "
                >
                  <span class="font-common">
                    Keterangan
                  </span>

                  <span
                    class="font-common"
                    style="
                    font-size: 12px;
                    color: #808080;
                  "
                  >
                    *Wajib diisi bila menolak
                  </span>
                </v-col>

                <v-col
                  cols="9"
                  sm="9"
                >
                  <v-textarea
                    id="verifikasi-keterangan"
                    v-model="verifikasiKeterangan"
                    label="Masukkan Keterangan"
                    color="purple"
                    no-resize
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row
                v-if="withdrawalMod"
                class="mt-n6"
              >
                <v-col class="text-right">
                  <v-btn
                    class="text-capitalize mx-2 font-common-white-bold"
                    style="
                width: 100px;
                height: 46px;
                background: #7A1878;
              "
                    @click="verifikasi(1)"
                  >
                    Verifikasi
                  </v-btn>

                  <v-btn
                    class="text-capitalize font-common-white-bold"
                    style="
                width: 71px;
                height: 46px;
                background: #EA3E3A;
              "
                    @click="verifikasi(0)"
                  >
                    Tolak
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </template>
        </v-card>
      </v-dialog>
    </v-tabs-items>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      tab: null,
      dialog: false,

      // Penarikan:
      search: '',
      headers: [
        { text: 'Tanggal Permintaan', value: 'tanggal_permintaan', sortable: false },
        { text: 'Nama Mitra', value: 'user_company_legal', sortable: false },
        { text: 'Tipe', value: 'user_type', sortable: false },
        { text: 'Nominal', value: 'nominal', sortable: false },
        { text: 'Status', value: 'status_string', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
      penarikan: [],

      // Penarikan Menunggu:
      penarikanMenunggu: [],
      pageMenunggu: 1,
      pageCountMenunggu: 0,
      itemCountMenunggu: 0,
      itemTotalMenunggu: 0,

      verifikasiKeterangan: '',

      xenditBalance: 0,

      selectedItem: null,

      recap: {
        jumlah_pending: 0,
      },

      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,

      // Permission:
      userMenus: localStorage.getItem('userMenus').split(','),
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),

      withdrawalMod () {
        let v = false
        if (this.userMenus.includes('WITHDRAWAL_MOD')) v = true

        return v
      },
    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
      this.getPenarikanMenunggu(1, this.itemsPerPage)
      this.getXendit()
    },

    methods: {
      initialize (page, limit, filter) {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')

        // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
        //   this.isAllowVerification = true
        // }

        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }

        axios.post('/v2/t/withdrawal', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.penarikan = res.data.data.list
            vm.recap = res.data.data.recap

            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getPenarikanMenunggu (page, limit, filter) {
        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
          status: '1',
        }

        axios.post('/v2/t/withdrawal', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.penarikanMenunggu = res.data.data.list

            vm.pageCountMenunggu = res.data.data.pagination.page_total
            vm.itemCountMenunggu = res.data.data.pagination.item_count
            vm.itemTotalMenunggu = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getXendit () {
        const vm = this

        const requestBody = {}

        axios.post('/v1/t/xendit/balance', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.xenditBalance = res.data.data.xendit_balance
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      toDetail (item) {
        console.log(item)
        this.selectedItem = item
        this.dialog = true
      },

      verifikasi (key) {
        if (key === 0 && this.verifikasiKeterangan === '') {
          setTimeout(() => {
            document.getElementById('verifikasi-keterangan').focus()
          }, 100)

          return
        }

        const requestBody = {
          withdraw_hash: this.selectedItem.hash,
          app_status: key,
          reason: this.verifikasiKeterangan,
        }

        axios.post('/v2/t/withdrawal/mod/approval', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.dialog = false
            this.$toast.success('Berhasil')
            this.verifikasiKeterangan = ''
            this.initialize(1, this.itemsPerPage)
            this.getXendit()
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #7A1878;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  color: #7A1878;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
